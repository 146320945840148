export const projectinfo = [
	// {
	// 	projectLiveLink: "curlyadmin-dev.herokuapp.com/demo/curlyadmin",
	// 	projectTitle: "CurlyAdmin.com",
	// 	projectDescription: "A virtual admin system",
	// 	thumb1: "curlyadmin_frontpage.png",
	// 	thumb2: "curlyadmin_dashboard.png",
	// 	projectBackCardTitle: "CurlyAdmin.com",
	// 	demoUrl1: "curlyadmin-dev.herokuapp.com/demo/curlyadmin",
	// 	demoUrl2: "curlyadmin-dev.herokuapp.com/demo/curlyadmin/Login",
	// 	techUsed: "Mongo DB, Express, React, Node.js (MERN Full Stack), Authentication Dashboard, CRUD, HTML, CSS, JavaScript",
	// 	apiUsed: "Sendgrid, Emailjs, Recaptcha, Stripe"
	// },
	{
		projectLiveLink: "master--mellow-cupcake-ad9f7b.netlify.app/",
		projectTitle: "GarbagePickup App",
		projectDescription: "Custom A.I. to detect when garbage was pickedup",
		thumb1: "garbagepickup2.png",
		thumb2: "garbagepickup.png",
		projectBackCardTitle: "GarbagePickup",
		demoUrl1: "master--mellow-cupcake-ad9f7b.netlify.app/",
		demoUrl2: "master--mellow-cupcake-ad9f7b.netlify.app/",
		techUsed: "Roboflow, React, Node.js, HTML, CSS, JavaScript, Socket.io, Twilio",
		apiUsed: "Roboflow, Twilio"
	},
	{
		projectLiveLink: "coderchat-c0a8c.web.app/",
		projectTitle: "CoderChat",
		projectDescription: "CoderChat - WhatsApp replica for coders",
		thumb1: "coderchat_login.png",
		thumb2: "coderchat_chat.png",
		projectBackCardTitle: "CoderChat",
		demoUrl1: "coderchat-c0a8c.web.app/",
		demoUrl2: "coderchat-c0a8c.web.app/",
		techUsed: "React, Node.js, HTML, CSS, JavaScript, Firebase",
		apiUsed: "Firebase Google authentication"
	},
	{
		projectLiveLink: "studentadminportalui-1187d.web.app/",
		projectTitle: "Student Tracker",
		projectDescription: "Student Database with UI/CRUD",
		thumb1: "studentportal_thumb1.png",
		thumb2: "studentportal_thumb2.png",
		projectBackCardTitle: "Student Portal",
		demoUrl1: "studentadminportalui-1187d.web.app/",
		demoUrl2: "studentadminportalui-1187d.web.app/",
		techUsed: ".Net, Azure, Angular, Node.js, HTML, CSS, JavaScript, Firebase",
		apiUsed: ".Net WebApi"
	},
	{
		projectLiveLink: "solidrockdesigns.io/",
		projectTitle: "Solid Rock Designs",
		projectDescription: "Personal Website Business",
		thumb1: "solidrock_home.png",
		thumb2: "solidrock_info.png",
		projectBackCardTitle: "Solid Rock Designs",
		demoUrl1: "solidrockdesigns.io/",
		demoUrl2: "solidrockdesigns.io/",
		techUsed: "React, Node.js, HTML, CSS, JavaScript, Vite",
		apiUsed: "EmailJS"
	},
	{
		projectLiveLink: "azrodentproofing.com/",
		projectTitle: "AZ Rodent Proofing",
		projectDescription: "Rodent Proofing website for client",
		thumb1: "azrodent_home.png",
		thumb2: "azrodent_services.png",
		projectBackCardTitle: "AZ Rodent Proofing",
		demoUrl1: "azrodentproofing.com/",
		demoUrl2: "azrodentproofing.com/services",
		techUsed: "React, Node.js, HTML, CSS, JavaScript",
		apiUsed: "EmailJS"
	},
	{
		projectLiveLink: "vickys-daycare.netlify.app/",
		projectTitle: "Vicky's Daycare",
		projectDescription: "Daycare sample website",
		thumb1: "vickys_home.png",
		thumb2: "vickys_staff.png",
		projectBackCardTitle: "Vickys Daycare",
		demoUrl1: "vickys-daycare.netlify.app/",
		demoUrl2: "vickys-daycare.netlify.app/ourstaff",
		techUsed: "React, Node.js, HTML, CSS, JavaScript, React Transitions",
		apiUsed: "EmailJS"
	},
	{
		projectLiveLink: "taqueria-sample.netlify.app/",
		projectTitle: "Taqueria Los Primos",
		projectDescription: "Taqueria Resturant Sample",
		thumb1: "taqueria_newThumb1.png",
		thumb2: "taqueria_newThumb2.png",
		projectBackCardTitle: "Taqueria Los Primos",
		demoUrl1: "taqueria-sample.netlify.app/",
		demoUrl2: "taqueria-sample.netlify.app/",
		techUsed: "React, Node.js, HTML, CSS, JavaScript",
		apiUsed: "EmailJS"
	},
	{
		projectLiveLink: "www.rockycoder.com/",
		projectTitle: "RockyCoder",
		projectDescription: "My portfolio website",
		thumb1: "rockyportfolio_frontpage.png",
		thumb2: "rockyportfolio_contact.png",
		projectBackCardTitle: "RockyCoder",
		demoUrl1: "www.rockycoder.com/",
		demoUrl2: "www.rockycoder.com/",
		techUsed: "React, Node.js, HTML, CSS, Heroku",
		apiUsed: "Emailjs"
	}
]